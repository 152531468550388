/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBWp9gJ28tlRNjJQHYO9R1xGfIfXsp0Bgk",
  "appId": "1:646532165658:web:76d8ab923f15376840a1a3",
  "authDomain": "schooldog-i-fayette-ga.firebaseapp.com",
  "measurementId": "G-GX6JFMTF1S",
  "messagingSenderId": "646532165658",
  "project": "schooldog-i-fayette-ga",
  "projectId": "schooldog-i-fayette-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-fayette-ga.appspot.com"
}
